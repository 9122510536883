<textarea
  id="desktop"
  [formControl]="textControl"
  [maxLength]="maxLength"
  [lang]="lang"
  (mouseleave)="hoveredSentenceIndex = null"
  enterkeyhint="enter"
  dir="auto"
  aria-labelledby="spoken-language-label"
  autofocus
  data-gramm_editor="false"></textarea>

@if (sentences$ | async; as sentences) {
  @if (sentences.length > 1) {
    <div id="sentences-overlay">
      @for (sentence of sentences; track sentence; let i = $index) {
        <span [class.hover]="hoveredSentenceIndex === i" (mouseenter)="hoveredSentenceIndex = i">{{ sentence }}</span>
      }
    </div>
  }
}
