<model-viewer
  #modelViewer
  alt="3D Sign Language Avatar"
  camera-controls
  camera-orbit="0deg 90deg auto"
  camera-target="0m 2.8m 0m"
  field-of-view="90deg"
  interaction-prompt="none"
  loading="eager"
  preload
  ar
  ar-scale="fixed"
  xr-environment
  ar-modes="scene-viewer webxr quick-look"></model-viewer>
