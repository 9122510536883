@if (inputMode$ | async; as inputMode) {
  @switch (inputMode) {
    @case ('upload') {
      @if (videoState$ | async; as videoState) {
        @if (!videoState.src) {
          <app-upload />
        } @else {
          <ng-container *ngTemplateOutlet="translationTemplate" />
        }
      }
    }
    @case ('webcam') {
      <ng-container *ngTemplateOutlet="translationTemplate" />
    }
  }
}

<ng-template #translationTemplate>
  <div class="interface">
    <div class="sign-language">
      <app-video [displayControls]="false" [displayFps]="false" />
    </div>

    <app-sign-writing />

    @if (spokenLanguageText$ | async; as translation) {
      <div class="translation-text">
        <div>{{ translation }}</div>

        @if (translation !== '') {
          <div>
            <app-text-to-speech [lang]="spokenLanguage$ | async" [text]="translation"></app-text-to-speech>

            <ion-button
              class="circle-icon"
              fill="clear"
              shape="round"
              color="dark"
              (click)="copyTranslation()"
              [attr.aria-label]="'translate.signed-to-spoken.actions.copy' | transloco"
              [matTooltip]="'translate.signed-to-spoken.actions.copy' | transloco"
              [matTooltipPosition]="'above'">
              <ion-icon name="copy-outline" slot="icon-only" />
            </ion-button>
          </div>
        }
      </div>
    }
  </div>
</ng-template>
