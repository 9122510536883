<ng-container *transloco="let t; read: 'settings.poseViewer'">
  <ion-fab id="viewer-selector">
    <ion-fab-button
      size="small"
      [color]="fab.color"
      [attr.aria-label]="t(fab.id)"
      [matTooltip]="t(fab.id)"
      matTooltipPosition="before">
      <ion-icon [name]="fab.icon" />
    </ion-fab-button>
    <ion-fab-list side="bottom">
      @for (button of fabButtons; track button) {
        <ion-fab-button
          [color]="button.color"
          [attr.aria-label]="t(button.id)"
          [matTooltip]="t(button.id)"
          matTooltipPosition="before"
          (click)="applySetting('poseViewer', button.id)">
          <ion-icon [name]="button.icon" />
        </ion-fab-button>
      }
    </ion-fab-list>
  </ion-fab>
</ng-container>
