export const IANASignedLanguages = [
  {signed: 'ads', spoken: 'ak', country: 'gh', abbreviation: ''}, // Adamorobe Sign Language
  {signed: 'sqk', spoken: 'sq', country: 'al', abbreviation: ''}, // Albanian Sign Language
  {signed: 'afg', spoken: 'ps', country: 'af', abbreviation: ''}, // Afghan Sign Language
  {signed: 'asp', spoken: 'ar', country: 'dz', abbreviation: ''}, // Algerian Sign Language
  {signed: 'syy', spoken: 'ar', country: 'ps', abbreviation: 'ABSL'}, // Al-Sayyid Bedouin Sign Language
  {signed: 'jks', spoken: 'ja', country: 'jp', abbreviation: ''}, // Amami Koniya Sign Language
  {signed: 'ase', spoken: 'en', country: 'us', abbreviation: 'ASL'}, // American Sign Language
  {signed: 'aed', spoken: 'es', country: 'ar', abbreviation: ''}, // Argentine Sign Language
  {signed: 'aen', spoken: 'hy', country: 'am', abbreviation: ''}, // Armenian Sign Language
  {signed: 'asf', spoken: 'en', country: 'au', abbreviation: 'Auslan'}, // Australian Sign Language
  {signed: 'asw', spoken: 'en', country: 'au', abbreviation: ''}, // Australian Aborigines Sign Language
  {signed: 'asq', spoken: 'de', country: 'at', abbreviation: 'ÖGS'}, // Austrian Sign Language
  {signed: 'bog', spoken: 'fr', country: 'ml', abbreviation: ''}, // Bamako Sign Language
  {signed: 'bfk', spoken: 'th', country: 'th', abbreviation: ''}, // Ban Khor Sign Language
  {signed: 'bqy', spoken: 'id', country: 'id', abbreviation: ''}, // Bengkala Sign Language
  {signed: 'bvl', spoken: 'es', country: 'bo', abbreviation: ''}, // Bolivian Sign Language
  {signed: 'bzs', spoken: 'pt', country: 'br', abbreviation: ''}, // Brazilian Sign Language
  {signed: 'bfi', spoken: 'en', country: 'gb', abbreviation: 'BSL'}, // British Sign Language
  {signed: 'bqn', spoken: 'bg', country: 'bg', abbreviation: ''}, // Bulgarian Sign Language
  {signed: 'lsb', spoken: 'fr', country: 'bi', abbreviation: ''}, // Burundian Sign Language
  {signed: 'csx', spoken: 'km', country: 'kh', abbreviation: ''}, // Cambodian Sign Language
  {signed: 'csc', spoken: 'ca', country: 'es', abbreviation: ''}, // Catalan Sign Language
  {signed: 'cds', spoken: 'fr', country: 'td', abbreviation: ''}, // Chadian Sign Language
  {signed: 'csd', spoken: 'th', country: 'th', abbreviation: ''}, // Chiangmai Sign Language
  {signed: 'csg', spoken: 'es', country: 'cl', abbreviation: ''}, // Chilean Sign Language
  {signed: 'csl', spoken: 'zh', country: 'cn', abbreviation: ''}, // Chinese Sign Language
  {signed: 'csn', spoken: 'es', country: 'co', abbreviation: ''}, // Colombian Sign Language
  {signed: 'csr', spoken: 'es', country: 'cr', abbreviation: ''}, // Costa Rican Sign Language
  {signed: 'csq', spoken: 'hr', country: 'hr', abbreviation: ''}, // Croatia Sign Language
  {signed: 'csf', spoken: 'es', country: 'cu', abbreviation: ''}, // Cuba Sign Language
  {signed: 'cse', spoken: 'cs', country: 'cz', abbreviation: 'CZJ'}, // Czech Sign Language
  {signed: 'dsl', spoken: 'da', country: 'dk', abbreviation: ''}, // Danish Sign Language
  {signed: 'doq', spoken: 'es', country: 'do', abbreviation: ''}, // Dominican Sign Language
  {signed: 'dse', spoken: 'nl', country: 'nl', abbreviation: 'NGT'}, // Dutch Sign Language
  {signed: 'ecs', spoken: 'es', country: 'ec', abbreviation: ''}, // Ecuadorian Sign Language
  {signed: 'esl', spoken: 'ar', country: 'eg', abbreviation: ''}, // Egypt Sign Language
  {signed: 'eso', spoken: 'et', country: 'ee', abbreviation: ''}, // Estonian Sign Language
  {signed: 'eth', spoken: 'am', country: 'et', abbreviation: ''}, // Ethiopian Sign Language
  {signed: 'fss', spoken: 'fi', country: 'fi', abbreviation: ''}, // Finland-Swedish Sign Language
  {signed: 'fse', spoken: 'fi', country: 'fi', abbreviation: ''}, // Finnish Sign Language
  {signed: 'fsl', spoken: 'fr', country: 'fr', abbreviation: 'LSF'}, // French Sign Language
  {signed: 'gsg', spoken: 'de', country: 'de', abbreviation: 'DGS'}, // German Sign Language
  {signed: 'gds', spoken: 'ne', country: 'np', abbreviation: ''}, // Ghandruk Sign Language
  {signed: 'gse', spoken: 'ak', country: 'gh', abbreviation: ''}, // Ghanaian Sign Language
  {signed: 'gss', spoken: 'el', country: 'gr', abbreviation: 'GSL'}, // Greek Sign Language
  {signed: 'gsm', spoken: 'es', country: 'gt', abbreviation: ''}, // Guatemalan Sign Language
  {signed: 'gus', spoken: 'fr', country: 'gn', abbreviation: ''}, // Guinean Sign Language
  {signed: 'haf', spoken: 'vi', country: 'vn', abbreviation: ''}, // Haiphong Sign Language
  {signed: 'hab', spoken: 'vi', country: 'vn', abbreviation: ''}, // Hanoi Sign Language
  {signed: 'hsl', spoken: 'ha', country: 'ng', abbreviation: ''}, // Hausa Sign Language
  {signed: 'hps', spoken: 'en', country: 'us', abbreviation: ''}, // Hawai'i Sign Language
  {signed: 'hos', spoken: 'vi', country: 'vn', abbreviation: ''}, // Ho Chi Minh City Sign Language
  {signed: 'hds', spoken: 'es', country: 'hn', abbreviation: ''}, // Honduras Sign Language
  {signed: 'hks', spoken: 'zh', country: 'hk', abbreviation: ''}, // Hong Kong Sign Language
  {signed: 'hsh', spoken: 'hu', country: 'hu', abbreviation: ''}, // Hungarian Sign Language
  {signed: 'icl', spoken: 'is', country: 'is', abbreviation: ''}, // Icelandic Sign Language
  {signed: 'ins', spoken: 'hi', country: 'in', abbreviation: 'ISL'}, // Indian Sign Language
  {signed: 'inl', spoken: 'id', country: 'id', abbreviation: ''}, // Indonesian Sign Language
  {signed: 'iks', spoken: 'iu', country: 'ca', abbreviation: ''}, // Inuit Sign Language
  {signed: 'isg', spoken: 'ga', country: 'ie', abbreviation: 'ISL'}, // Irish Sign Language
  {signed: 'isr', spoken: 'he', country: 'il', abbreviation: 'ISL'}, // Israeli Sign Language
  {signed: 'ise', spoken: 'it', country: 'it', abbreviation: 'LIS'}, // Italian Sign Language
  {signed: 'jcs', spoken: 'en', country: 'jm', abbreviation: ''}, // Jamaican Country Sign Language
  {signed: 'jls', spoken: 'en', country: 'jm', abbreviation: ''}, // Jamaican Sign Language
  {signed: 'jsl', spoken: 'ja', country: 'jp', abbreviation: 'JSL'}, // Japanese Sign Language
  {signed: 'jhs', spoken: 'ne', country: 'np', abbreviation: ''}, // Jhankot Sign Language
  {signed: 'jos', spoken: 'ar', country: 'jo', abbreviation: ''}, // Jordanian Sign Language
  {signed: 'jos', spoken: 'ar', country: 'sy', abbreviation: ''}, // Syrian Sign Language
  {signed: 'jus', spoken: 'ne', country: 'np', abbreviation: ''}, // Jumla Sign Language
  {signed: 'xki', spoken: 'en', country: 'ke', abbreviation: ''}, // Kenyan Sign Language
  {signed: 'kvk', spoken: 'ko', country: 'kr', abbreviation: ''}, // Korean Sign Language
  {signed: 'sqx', spoken: 'ar', country: 'ps', abbreviation: ''}, // Kufr Qassem Sign Language
  {signed: 'lso', spoken: 'lo', country: 'la', abbreviation: ''}, // Laos Sign Language
  {signed: 'lsl', spoken: 'lv', country: 'lv', abbreviation: ''}, // Latvian Sign Language
  {signed: 'lbs', spoken: 'ar', country: 'ly', abbreviation: ''}, // Libyan Sign Language
  {signed: 'lls', spoken: 'lt', country: 'lt', abbreviation: ''}, // Lithuanian Sign Language
  {signed: 'lsg', spoken: 'fr', country: 'fr', abbreviation: ''}, // Lyons Sign Language
  {signed: 'mzc', spoken: 'fr', country: 'mg', abbreviation: ''}, // Madagascar Sign Language
  {signed: 'lws', spoken: 'en', country: 'mw', abbreviation: ''}, // Malawian Sign Language
  {signed: 'xml', spoken: 'ms', country: 'my', abbreviation: ''}, // Malaysian Sign Language
  {signed: 'mdl', spoken: 'mt', country: 'mt', abbreviation: ''}, // Maltese Sign Language
  {signed: 'nsr', spoken: 'en', country: 'ca', abbreviation: ''}, // Maritime Sign Language
  {signed: 'lsy', spoken: 'en', country: 'mu', abbreviation: ''}, // Mauritian Sign Language
  {signed: 'mre', spoken: 'en', country: 'us', abbreviation: 'MVSL'}, // Martha's Vineyard Sign Language
  {signed: 'mfs', spoken: 'es', country: 'mx', abbreviation: ''}, // Mexican Sign Language
  {signed: 'rsm', spoken: 'en', country: 'au', abbreviation: ''}, // Miriwoong Sign Language
  {signed: 'ehs', spoken: 'ja', country: 'jp', abbreviation: ''}, // Miyakubo Sign Language
  {signed: 'vsi', spoken: 'ro', country: 'md', abbreviation: ''}, // Moldova Sign Language
  {signed: 'mzg', spoken: 'fr', country: 'fr', abbreviation: ''}, // Monastic Sign Language
  {signed: 'msr', spoken: 'mn', country: 'mn', abbreviation: ''}, // Mongolian Sign Language
  {signed: 'xms', spoken: 'ar', country: 'ma', abbreviation: ''}, // Moroccan Sign Language
  {signed: 'mzy', spoken: 'pt', country: 'mz', abbreviation: ''}, // Mozambican Sign Language
  {signed: 'ysm', spoken: 'my', country: 'mm', abbreviation: ''}, // Myanmar Sign Language
  {signed: 'nbs', spoken: 'en', country: 'na', abbreviation: ''}, // Namibian Sign Language
  {signed: 'nsp', spoken: 'ne', country: 'np', abbreviation: ''}, // Nepalese Sign Language
  {signed: 'nzs', spoken: 'en', country: 'nz', abbreviation: 'NZSL'}, // New Zealand Sign Language
  {signed: 'ncs', spoken: 'es', country: 'ni', abbreviation: ''}, // Nicaraguan Sign Language
  {signed: 'nsi', spoken: 'en', country: 'ng', abbreviation: ''}, // Nigerian Sign Language
  {signed: 'nsl', spoken: 'no', country: 'no', abbreviation: ''}, // Norwegian Sign Language
  {signed: 'okl', spoken: 'en', country: 'gb', abbreviation: ''}, // Old Kentish Sign Language
  {signed: 'pks', spoken: 'ur', country: 'pk', abbreviation: ''}, // Pakistan Sign Language
  {signed: 'lsp', spoken: 'es', country: 'pa', abbreviation: ''}, // Panamanian Sign Language
  {signed: 'pgz', spoken: 'en', country: 'pg', abbreviation: ''}, // Papua New Guinean Sign Language
  {signed: 'pys', spoken: 'es', country: 'py', abbreviation: ''}, // Paraguayan Sign Language
  {signed: 'psg', spoken: 'ms', country: 'my', abbreviation: ''}, // Penang Sign Language
  {signed: 'psc', spoken: 'fa', country: 'ir', abbreviation: ''}, // Persian Sign Language
  {signed: 'prl', spoken: 'es', country: 'pe', abbreviation: ''}, // Peruvian Sign Language
  {signed: 'psp', spoken: 'en', country: 'ph', abbreviation: ''}, // Philippine Sign Language
  {signed: 'psd', spoken: 'en', country: 'us', abbreviation: ''}, // Plains Indian Sign Language
  {signed: 'pso', spoken: 'pl', country: 'pl', abbreviation: 'PJM'}, // Polish Sign Language
  {signed: 'psr', spoken: 'pt', country: 'pt', abbreviation: ''}, // Portuguese Sign Language
  {signed: 'prz', spoken: 'es', country: 'co', abbreviation: ''}, // Providencia Sign Language
  {signed: 'psl', spoken: 'es', country: 'pr', abbreviation: 'PRSL'}, // Puerto Rican Sign Language
  {signed: 'fcs', spoken: 'fr', country: 'ca', abbreviation: 'LSQ'}, // Quebec Sign Language
  {signed: 'rsi', spoken: 'en', country: 'sb', abbreviation: ''}, // Rennellese Sign Language
  {signed: 'rms', spoken: 'ro', country: 'ro', abbreviation: ''}, // Romanian Sign Language
  {signed: 'rsl', spoken: 'ru', country: 'ru', abbreviation: ''}, // Russian Sign Language
  {signed: 'esn', spoken: 'es', country: 'sv', abbreviation: ''}, // Salvadoran Sign Language
  {signed: 'sdl', spoken: 'ar', country: 'sa', abbreviation: ''}, // Saudi Arabian Sign Language
  {signed: 'kgi', spoken: 'ms', country: 'my', abbreviation: ''}, // Selangor Sign Language
  {signed: 'sgx', spoken: 'en', country: 'sl', abbreviation: ''}, // Sierra Leone Sign Language
  {signed: 'lsv', spoken: 'en', country: 'us', abbreviation: ''}, // Sivia Sign Language
  {signed: 'svk', spoken: 'sk', country: 'sk', abbreviation: 'SPJ'}, // Slovakian Sign Language
  {signed: 'sls', spoken: 'en', country: 'sg', abbreviation: ''}, // Singapore Sign Language
  {signed: 'szs', spoken: 'en', country: 'sb', abbreviation: ''}, // Solomon Islands Sign Language
  {signed: 'sfb', spoken: 'fr', country: 'be', abbreviation: ''}, // French Belgian Sign Language
  {signed: 'sfs', spoken: 'af', country: 'za', abbreviation: ''}, // South African Sign Language
  {signed: 'ssp', spoken: 'es', country: 'es', abbreviation: 'LSE'}, // Spanish Sign Language
  {signed: 'sqs', spoken: 'si', country: 'lk', abbreviation: ''}, // Sri Lankan Sign Language
  {signed: 'swl', spoken: 'sv', country: 'se', abbreviation: 'STS'}, // Swedish Sign Language
  {signed: 'ssr', spoken: 'fr', country: 'ch', abbreviation: 'LSF-CH'}, // Swiss-French Sign Language
  {signed: 'sgg', spoken: 'de', country: 'ch', abbreviation: 'DSGS'}, // Swiss-German Sign Language
  {signed: 'slf', spoken: 'it', country: 'ch', abbreviation: 'LIS-CH'}, // Swiss-Italian Sign Language
  {signed: 'tss', spoken: 'zh', country: 'tw', abbreviation: ''}, // Taiwan Sign Language
  {signed: 'tza', spoken: 'sw', country: 'tz', abbreviation: ''}, // Tanzanian Sign Language
  {signed: 'tsy', spoken: 'en', country: 'ng', abbreviation: ''}, // Tebul Sign Language
  {signed: 'lsn', spoken: 'bo', country: 'cn', abbreviation: ''}, // Tibetan Sign Language
  {signed: 'tsq', spoken: 'th', country: 'th', abbreviation: ''}, // Thai Sign Language
  {signed: 'lst', spoken: 'en', country: 'tt', abbreviation: ''}, // Trinidad and Tobago Sign Language
  {signed: 'tse', spoken: 'ar', country: 'tn', abbreviation: ''}, // Tunisian Sign Language
  {signed: 'tsm', spoken: 'tr', country: 'tr', abbreviation: ''}, // Turkish Sign Language
  {signed: 'ugn', spoken: 'en', country: 'ug', abbreviation: ''}, // Ugandan Sign Language
  {signed: 'ukl', spoken: 'uk', country: 'ua', abbreviation: ''}, // Ukrainian Sign Language
  {signed: 'uks', spoken: 'pt', country: 'br', abbreviation: ''}, // Urubú-Kaapor Sign Language
  {signed: 'ugy', spoken: 'es', country: 'uy', abbreviation: ''}, // Uruguayan Sign Language
  {signed: 'vgt', spoken: 'nl', country: 'be', abbreviation: ''}, // Flemish Sign Language
  {signed: 'vsv', spoken: 'ca', country: 'es', abbreviation: ''}, // Valencian Sign Language
  {signed: 'vsl', spoken: 'es', country: 've', abbreviation: ''}, // Venezuelan Sign Language
  {signed: 'wbs', spoken: 'bn', country: 'in', abbreviation: ''}, // West Bengal Sign Language
  {signed: 'yhs', spoken: 'en', country: 'au', abbreviation: ''}, // Yan-nhaŋu Sign Language
  {signed: 'yds', spoken: 'yi', country: 'us', abbreviation: ''}, // Yiddish Sign Language
  {signed: 'ygs', spoken: 'en', country: 'au', abbreviation: ''}, // Yolŋu Sign Language
  {signed: 'msd', spoken: 'es', country: 'my', abbreviation: ''}, // Yucatec Maya Sign Language
  {signed: 'ysl', spoken: 'sr', country: 'yu', abbreviation: ''}, // Yugoslavian Sign Language
  {signed: 'ysl', spoken: 'sr', country: 'rs', abbreviation: ''}, // Yugoslavian Sign Language
  {signed: 'zsl', spoken: 'en', country: 'zm', abbreviation: ''}, // Zambian Sign Language
  {signed: 'zib', spoken: 'en', country: 'zw', abbreviation: ''}, // Zimbabwe Sign Language
  {signed: 'ils', spoken: '', country: '', abbreviation: 'IS'}, // International Sign

  {signed: 'rsl-by', spoken: 'be', country: 'by', abbreviation: ''}, // Russian Sign Language (in Belarus, instead of Belarusian Sign Language)
  {signed: 'gss-cy', spoken: 'el', country: 'cy', abbreviation: ''}, // Greek Sign Language (in Cyprus, instead of Cypriot Sign Language)
];
