<ion-toolbar>
  <ion-buttons slot="start">
    <app-upload [isMobile]="true" />
  </ion-buttons>

  <ion-title>
    <ion-fab-button color="danger">
      <ion-icon name="ellipse-outline" />
    </ion-fab-button>
  </ion-title>

  <ion-buttons slot="end">
    <ion-button>
      <ion-icon name="camera-reverse-outline" slot="icon-only" />
    </ion-button>
  </ion-buttons>
</ion-toolbar>
