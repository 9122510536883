<app-language-selector
  [flags]="true"
  [hasLanguageDetection]="false"
  [languages]="translation.signedLanguages"
  [language]="signedLanguage$ | async"
  translationKey="signedLanguagesShort"
  (languageChange)="setSignedLanguage($event)"></app-language-selector>

<ion-button
  (click)="swapLanguages()"
  id="swap-languages"
  fill="clear"
  expand="full"
  color="dark"
  [disabled]="(spokenLanguage$ | async) === null && (detectedLanguage$ | async) === null"
  [matTooltip]="'translate.swapLanguages' | transloco"
  [attr.aria-label]="'translate.swapLanguages' | transloco">
  <ion-icon name="swap-horizontal" />
</ion-button>

<app-language-selector
  [hasLanguageDetection]="spokenToSigned"
  [languages]="translation.spokenLanguages"
  [language]="spokenLanguage$ | async"
  translationKey="languages"
  (languageChange)="setSpokenLanguage($event)"></app-language-selector>
