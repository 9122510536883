@if (videoUrl) {
  <video
    [src]="safeVideoUrl"
    autoplay
    loop
    muted
    playsinline
    height="100%"
    width="100%"
    (error)="onVideoError($event)"
    (click)="playVideoIfPaused($event)"></video>
} @else {
  @if (pose$ | async; as pose) {
    @if (poseViewerSetting$ | async; as poseViewerSetting) {
      @if (poseViewerSetting === 'pose') {
        <app-skeleton-pose-viewer [src]="pose" />
      } @else if (poseViewerSetting === 'person') {
        <app-human-pose-viewer [src]="pose" />
      } @else if (poseViewerSetting === 'avatar') {
        <app-avatar-pose-viewer [src]="pose" />
      }
    }
  }
}
@if (pose$ | async; as pose) {
  <div class="actions-row">
    @if (videoUrl) {
      <ion-button
        class="circle-icon"
        fill="clear"
        shape="round"
        color="dark"
        (click)="downloadTranslation()"
        [attr.aria-label]="'translate.spoken-to-signed.actions.download' | transloco"
        [matTooltip]="'translate.spoken-to-signed.actions.download' | transloco"
        [matTooltipPosition]="'above'">
        <ion-icon name="download-outline" slot="icon-only" />
      </ion-button>
      @if (isSharingSupported) {
        <ion-button
          class="circle-icon"
          fill="clear"
          shape="round"
          color="dark"
          (click)="shareTranslation()"
          [attr.aria-label]="'translate.spoken-to-signed.actions.share' | transloco"
          [matTooltip]="'translate.spoken-to-signed.actions.share' | transloco"
          [matTooltipPosition]="'above'">
          <ion-icon ios="share-outline" md="share-social-outline" slot="icon-only" />
        </ion-button>
      }
    } @else {
      <ion-button [disabled]="true" class="circle-icon" fill="clear" shape="round">
        <ion-spinner name="crescent" color="dark"></ion-spinner>
      </ion-button>
    }
  </div>
  <app-viewer-selector></app-viewer-selector>
}
