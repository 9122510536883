<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button routerLink="/about" queryParamsHandling="preserve" color="primary" shape="round" fill="solid">
        {{ 'landing.about.title' | transloco }}
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-logo [text]="'translate.logo' | transloco" />
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="container">
    <div id="input-mode-group">
      @if (spokenToSigned) {
        <app-translate-input-button mode="text" icon="language" />
      } @else {
        <app-translate-input-button mode="webcam" icon="videocam" />
        <app-translate-input-button mode="upload" icon="cloud-upload" />
      }
    </div>

    <app-drop-pose-file>
      <div id="translation-card">
        <app-language-selectors />

        <div id="content">
          @if (spokenToSigned) {
            <!-- Spoken to sign requires a text input and video output -->
            <app-spoken-to-signed />
          } @else {
            <!-- Sign to spoken requires a video input and text output -->
            <app-signed-to-spoken />
          }
        </div>
      </div>
    </app-drop-pose-file>

    <app-send-feedback />
  </div>
</ion-content>
